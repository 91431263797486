.navbar-container{
    height: 80px;
    background-color: white;
    position: fixed;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0px 17px 17.7px 8px rgba(0, 0, 0, 0.144) ;
    z-index: 1000;
}
.navbar-link-container{
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 800px;
    margin-left: 40%;
    color: #2F2339;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.3s ease;
}
.navbar-link{
    position: relative;
  padding-bottom: 8px; /* Adjust the padding to your preference */
  cursor: pointer;
  text-decoration: none; /* Remove default underline */
}
.navbar-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 4px;
    bottom: 5px;
    left: 0;
    background-color: #0086FF; /* Adjust the color of the underline */
    transition: width 0.3s ease; /* Add smooth transition to the width */
  }
  
  .navbar-link:hover::before {
    width: 100%; /* Expand the underline to full width on hover */
  }
.navbar-image{
    width: 250px;
    margin-top: -3%;
}
.navbar-image-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 15%;
}