.equipe-container{
    height: 1300px;
    /* background-color: #ffcc98; */
    background-color: rgb(61, 61, 61);
    position: relative;
    z-index: 5;
    font-family: "Rowdies";
}
.equipe-title{
    font-size: 120px;
    font-family: 'Rowdies';
    color: white;
    font-weight: bold;
    padding-top: 5%;
}
.equipe-background-photo{
    width: 900px;
    position: absolute;
    top: 12.2%; 
    left: 23.7%;
    opacity: 1;
    /* filter: blur(0.5px); */
}

img.equipe-Edouard-photo{
    position: absolute;
    left: 23.7%;
    top: 12.2%;
    width: 900px;
    opacity: 0;
    transition: opacity 0.3s ease;
    filter: drop-shadow(-3px 5px 10px rgba(0, 0, 0, 0.375));
}
img.equipe-Heloise-photo{
    position: absolute;
    left: 23.7%;
    top: 12.2%;
    width: 900px;
    opacity: 0;
    transition: opacity 0.3s ease;
    filter: drop-shadow(-3px 5px 10px rgba(0, 0, 0, 0.375));
}
img.equipe-Hichem-photo{
    position: absolute;
    left: 23.7%;
    top: 12.2%;
    width: 900px;
    opacity: 0;
    transition: opacity 0.3s ease;
    filter: drop-shadow(-3px 5px 10px rgba(0, 0, 0, 0.375));
}
img.equipe-Justine-photo{
    position: absolute;
    left: 23.7%;
    top: 12.2%;
    width: 900px;
    opacity: 0;
    transition: opacity 0.3s ease;
    filter: drop-shadow(-3px 5px 10px rgba(0, 0, 0, 0.375));
}
img.equipe-Karine-photo{
    position: absolute;
    left: 23.7%;
    top: 12.2%;
    width: 900px;
    opacity: 0;
    transition: opacity 0.3s ease;
    filter: drop-shadow(-3px 5px 10px rgba(0, 0, 0, 0.375));
}
img.equipe-Maxime-photo{
    position: absolute;
    left: 23.7%;
    top: 12.2%;
    width: 900px;
    opacity: 0;
    transition: opacity 0.3s ease;
    filter: drop-shadow(-3px 5px 10px rgba(0, 0, 0, 0.375));
}
.equipe-Timothe-photo{
    position: absolute;
    left: 23.7%;
    top: 12.2%;
    width: 900px;
    transition: opacity 0.3s ease;
    opacity: 0;
    filter: drop-shadow(-3px 5px 10px rgba(0, 0, 0, 0.375));
}
img.equipe-Victor-photo{
    position: absolute;
    left: 23.7%;
    top: 12.2%;
    width: 900px;
    opacity: 0;
    transition: opacity 0.3s ease;
    filter: drop-shadow(-3px 5px 10px rgba(0, 0, 0, 0.375));
}
.equipe-carouselle-card{
    width: 400px;
    height: 300px;
    border-radius: 10px;
    background-color: white;
    margin-left: 40px;
    display: inline-block;
}
.equipe-carouselle-card:hover{
    cursor: pointer;
}

.equipe-carouselle-Name{
    text-align: left;
    padding-top: 10%;
    font-size: 32px;
    margin-left: 5%;
    color: #F58D47;
    width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;

}
.equipe-carouselle-Title{
    text-align: left;
    margin-top: 5%;
    margin-left: 5%;
    color: #2F2339;
    font-size: 20px;
    width: 350px;
    /* word-break: break-all; */
    white-space: normal;
    height: 100px;
}
.equipe-carouselle-Quote {
    text-align: left;
    margin-top: 10%;
    margin-left: 5%;
    color: #2F2339;
    font-size: 18px;
    width: 350px;
    color: #F58D47;
    height: auto; /* Remove fixed height */
}
.equipe-carouselle-container{
    animation: 15s slide infinite linear; 
    display: inline-block;
    height: 300px;
}

.equipe-carouselle{
    position: absolute;
    top: 60%;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
}
.equipe-carouselle:hover .equipe-carouselle-container {
    animation-play-state: paused;
}
@keyframes slide{
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}
.equipe-carouselle::before{
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 300px;
    background: linear-gradient(to left, rgba(255,255,255,0), rgb(61, 61, 61));
    content: "";
    z-index: 2;
}
.equipe-carouselle::after{
    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
    height: 300px;
    background: linear-gradient(to right, rgba(255,255,255,0), rgb(61, 61, 61));
    content: "";
    z-index: 2;
}
.equipe-carouselle-card:hover {
    background-color: #fcdcc6;
    transition: background-color 0.3s ease-in;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.575));
}
.equipe-web{
    opacity: 0;
    position: absolute;
    height: 500px;
    width: 300px;
}