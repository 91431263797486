
.intro-container{
    height: 1050px;
    background-color: #FFB165 ;
    font-family: Rowdies;
    color: #2F2339;
}
.intro-container-object{
    padding-top: 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}
.intro-image-container{
    margin-left: 7%;
}
.intro-image{
    width: 700px;
}
.intro-text-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5%;

}
.intro-title{
    font-family: 'Rowdies';
    font-size: 80px;
    font-weight: bold;
    text-align: left;
    width: 90%;
}
.intro-sub{
    font-family: 'Source Sans Pro';
    font-size: 28px;
    text-align: left;
    margin-top: 2%;
}
.intro-subbold{
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 42px;
    font-weight: bold;
    text-align: left;
    margin-top: 2%;
}

@media screen and (max-width: 1460px) {
    .intro-container {
        height: 700px; /* Adjust height as needed for smaller screens */
    }

    .intro-container-object {
        flex-direction: row;
        padding-top: 15%;
    }

    .intro-image-container {
        margin-left: 0;
    }

    .intro-image {
        width: 70%; /* Make the image fill the container */
    }

    .intro-text-container {
        margin-left: 0;
    }

    .intro-title {
        font-size: 48px; /* Adjust font size for smaller screens */
        width: 50%;
    }

    .intro-sub {
        font-size: 18px; /* Adjust font size for smaller screens */
        width: 50%;
    }

    .intro-subbold {
        font-size: 24px; /* Adjust font size for smaller screens */
        width: 50%;
    }
}

@media screen and (max-width: 1200px) {
    .intro-container {
        height: 600px; /* Adjust height as needed for smaller screens */
    }

    .intro-container-object {
        flex-direction: row;
        padding-top: 15%;
    }

    .intro-image {
        width: 100%; /* Make the image fill the container */
        padding-left: 10%;
    }

    .intro-text-container {
        margin-left: 10%;
    }

    .intro-title {
        font-size: 42px; /* Adjust font size for smaller screens */
        width: 70%;
    }

    .intro-sub {
        font-size: 18px; /* Adjust font size for smaller screens */
        width: 90%;
    }

    .intro-subbold {
        font-size: 24px; /* Adjust font size for smaller screens */
        width: 90%;
    }
}
@media screen and (max-width: 820px) {
    .intro-container {
        height: 500px; /* Adjust height as needed for smaller screens */
    }

    .intro-container-object {
        flex-direction: row;
        padding-top: 15%;
    }

    .intro-image-container {
        margin-left: 0;
    }

    .intro-image {
        width: 80%; /* Make the image fill the container */
        padding-left: 10%;
    }

    .intro-text-container {
        margin-left: 0%;
    }

    .intro-title {
        font-size: 32px; /* Adjust font size for smaller screens */
        width: 80%;
    }

    .intro-sub {
        font-size: 18px; /* Adjust font size for smaller screens */
        width: 95%;
    }

    .intro-subbold {
        font-size: 24px; /* Adjust font size for smaller screens */
        width: 90%;
    }
}
