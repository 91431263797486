.contact-container{
    height: 1000px;
    background-color: #2F2339;
    color: white;
    font-family: "Source Sans Pro", sans-serif;
    text-align: left;
    display: flex;
    flex-direction: row;
}
.contact-text-container{
    padding-top: 10%;
    padding-left: 10%;
    width: 600px;
}
.contact-title{
    font-size: 64px;
    font-weight: bold;
    width: 100%;
}
.contact-text{
    font-size: 28px;
    width: 100%;
    margin-top: 5%;
}
.contact-form{
    display: flex;
    flex-direction: column;
}
.contact-form-container{
    margin-top: 10%;
    margin-left: 5%;
}
.contact-nom{
    width: 700px;
    height: 40px;
    border-radius: 5px;
    padding-left: 5%;
}
.contact-email{
    width: 700px;
    margin-top: 2%;
    height: 40px;
    border-radius: 5px;
    padding-left: 5%;
}
.contact-form-text{
    width: 700px;
    margin-top: 2%;
    height: 400px;
    border-radius: 5px;
    padding-left: 5%;
    padding-top: 2%;
}
.contact-button{
    margin-top: 2%;
    width: 100px;
    height: 40px;
    border-radius: 5px;
}
.popup{
    position: fixed;
    width: 250px;
    height: 80px;
    top: 10%;
    left: 45%;
    z-index: 999;
    background-color: #b7ffb6;
    color: #2F2339;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 20px;
}