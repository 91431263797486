.commanditaire-container{
    height: 1400px;
    background: white;
    position: relative;
    font-family: 'Rowdies';
    width: 100%;
    color: #2F2339;
}
.commanditaire-text-container{
    position: relative;
    left: 10%;
    padding-top: 20%;
    text-align: left;
    z-index: 5;
}
.text-indent{
    padding-left: 5%;
}
.commandiraire-title{
    font-size: 64px;
}

.commanditaire-sub{
    font-size: 36px;
    margin-top: 5%;
}
.commanditaire-text{
    font-family: 'source sans pro';
    font-size: 36px;
    width: 700px;
    margin-top: 1%;
}
.commanditaire-text-orange{
    font-family: 'source sans pro';
    color: #F58D47;
    margin-top: 1%;
    font-size: 36px;
}
.commanditaire-text-orange-date{
    font-family: 'source sans pro';
    /* color: #F58D47; */
    margin-top: 1%;
    font-size: 36px;
    width: 900px;
}
.commanditaire-text-orange-sub{
    font-family: 'source sans pro';
    color: #F58D47;
    margin-top: 1%;
    font-size: 36px;
    width: 900px;
}
