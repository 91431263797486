.sidebar-hamburger{
    width: 50px;
}
.sidebar{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: black;
}

.sidebar-bg{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 400px;
    background-color: white;
    box-shadow: 0px 17px 17.7px 8px rgba(0, 0, 0, 0.144) ;

}

.sidebar-links{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
a{
    color: #2F2339;
    text-decoration: none;
    font-size: 22px;
}
a:hover{
    transition: transform 0.5s;
    transform: scale(1.05);
}