.togglebutton-hamburger{
    width: 50px;
}
.togglebutton-container{
    border: none;
    border-radius: 20px;
    background-color: transparent;
    cursor: pointer;
    position: fixed;
    top: 12px;
    left: 10%;
}