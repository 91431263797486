.footer-container{
    height: 330px;
    background-color: #4F4F4F;
    position: relative;
    color: white;
}
.footer-rights{
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 22px;
    bottom: 5px;
    left: 42%;
    font-Family: 'Source Sans Pro, sans-serif';
    letter-spacing: 1px;
}
.footer-rights-img{
    margin-left: 5px;
    margin-right: 5px;
}
.footer-logo{
    position: absolute;
    right: 10%;
    top: 20%;
}
.footer-logo3D{
    width: 200px;
}
.footer-social{
    text-align: left;
    position: absolute;
    top: 35%;
    left: 55%;
    font-size: 22px;
}
.footer-social div{
    margin-top: 5%;
}
.footer-contact{
    position: absolute;
    top: 35%;
    left: 30%;
    display: flex;
    flex-direction: row;
}
.footer-contact div{
    margin-left: 15%;
}
.footer-AEN{
    position: absolute;
    filter: drop-shadow(-3px 5px 10px rgba(0, 0, 0, 0.375));
    left: 10%;
    top: 20%;
}