.objectif-container{
    height: 1200px;
    position: relative;
    font-family: "Rowdies";
    color: #2F2339;
}
.objectif-title{
    font-size: 64px;
    font-family: 'Rowdies';
    position: absolute;
    text-align: left;
    left: 10%;
    top: 10%
}
.objectif-box-container{
    padding-top: 20%;
    width: 200%;
    display: flex;
    flex-direction: row;
}
.objectif-box-outer-container {
    position: relative;
    overflow: hidden;
    z-index: 5;
    padding-left: 20%;
    padding-top: 20%;
  }
  
  .objectif-box-inner-container {
    height: 1000px;
    width: 200%;
    /* padding-left: 20%;
    padding-top: 20%; */
    display: flex;
    transition: transform 0.5s ease; /* Add smooth transition for scrolling */
    z-index: 5;
  }
.objectif-box{
    width: 500px;
    height: 400px;
    /* border: 1px solid black; */
    margin-left: 2%;
    border-radius: 5px;
    box-shadow: 0px 6px 35.1px 0px rgba(0, 0, 0, 0.25);
    text-align: left;
    z-index: 5;
    background-color: white;
}
.objectif-arrow-container{
    position: absolute;
    top: 11%;
    left: 40%;
    display: flex;
    flex-direction: row;
    width: 200px;
    z-index: 6;
    
}
.objectif-arrow-left{
    position: relative;
    width: 50px;
    height: 50px;
    text-decoration: none;
    background-color: white;
    cursor: pointer;
    border-radius: 10px;
    transition: background-color 0.2s ease-in;
}
.objectif-arrow-right{
    position: relative;
    margin-left: 10%;
    width: 50px;
    height: 50px;
    background-color: white;
    text-decoration: none;
    cursor: pointer;
    border-radius: 10px;
    transition: background-color 0.2s ease-in;
}
.objectif-arrow-right:hover{
    background-color: #F58D47 ;
}
.objectif-arrow-left:hover{
    background-color: #F58D47 ;
}
.objectif-box-title{
    margin-top: 2%;
    margin-left: 10%;
    font-size: 40px;
}
.objectif-box-text{
    margin-left: 10%;
    font-family: "Source Sans Pro";
    font-size: 22px;
    margin-top: 5%;
    overflow: hidden;
    height: 150px;
    width: 85%;
}
.objectif-box-sub{
    margin-left: 10%;
    color: #F58D47;
    font-size: 30px;
    margin-top: 2%;
}
.objectif-box-image{
    margin-top: 5%;
    margin-left: 10%;
    width: 70px;
    height: 70px;
}
.objectif-right-arrow{
    position: absolute;
    width: 30px;
    height: 30px;
    top:7px;
    left: 8px;
    transform: rotatey(180deg);
    color: white;
}
.objectif-left-arrow{
    position: absolute;
    width: 30px;
    height: 30px;
    top:7px;
    left: 7px;
}
.objectif-cubic{
    position: absolute;
    top: 15%;
    right: 10%;
    z-index: 1;
}
.objectif-cubic-image{
    width: 800px;
    position: relative;
    z-index: 1;
}