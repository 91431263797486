.fondateur-container{
    background-color: #2F2339;
    height: 1400px;
    font-family: 'Rowdies';
    position: relative;
    z-index: 5;
}
.fondateur-container-inside{
    background-color: #2F2339;
    height: 1400px;
    position: relative;
    z-index: 5;
}
.fondateur-title{
    font-size: 80px;
    font-family: 'Rowdies';
    color: white;
    padding-top: 10%;
    text-align: left;
    padding-left: 10%;
}
.fondateur-image-container{
    position: relative;
    margin-top: 5%;
    z-index: 5;
}
.fondateur-orange{
    width: 620px;
    height: 453px;
    background-color: #F58D47;
    position: absolute;
    border-radius: 15px;
    z-index: 2;
    left: 10%;
    top: 30px;
}
.fondateur-image{
    position: absolute;
    width: 1000px;
    left: 12%;
    z-index: 5;

}

.fondateur-quote-container{
    position: relative;
    height: 100%;
    z-index: 1;
}

.fondateur-white{
    background-color: white;
    position: absolute;
    width: 2000px;
    height: 550px;
    z-index: 1;
    left: 30%;
    border-radius: 15px ;
}

.fondateur-Name{
    color: #F58D47;
    position: absolute;
    top: 30%;
    left: 50%;
    z-index: 3;
    font-size: 38px;
}
.fondateur-position{
    color: #F58D47;
    position: absolute;
    left: 50%;
    top: 33%;
    z-index: 3;
    font-size: 32px;
    font-family: 'Source Sans Pro', sans-serif;
}
.fondateur-quoted{
    z-index: 5;
    position: absolute;
    width: 90px;
    top: 1%;
    left: 45%;
}
.fondateur-bar{
    position: absolute;
    z-index: 5;
    height: 300px;
    width: 10px;
    background-color: rgb(207, 207, 207);
    left: 47%;
    top: 7%;
}
.fondateur-quote{
    color: #2F2339;
    position: absolute;
    left: 50%;
    z-index: 5;
    top: 7%;
    font-size: 36px;
    text-align: left;
    width: 800px;
    letter-spacing: 1;
    line-height: 1;
}

.fondateur-cubic{
    position: absolute;
    top: 75%;
    right: 10%;
    z-index: 1;
}
.fondateur-cubic-image{
    width: 800px;
    position: relative;
    z-index: 1;
}